import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const fresh = [
  {
    videoID: "I7jBg5-9918",
    company: "AETHETICS",
    title: "RIDE OR DIE",
    thumbnail: "https://i.ytimg.com/vi/I7jBg5-9918/hqdefault.jpg"
  },
  {
    videoID: "RN6c4ujRd0E",
    company: "ALIEN WORKSHOP",
    title: "TIME CODE",
    thumbnail: "https://i.ytimg.com/vi/RN6c4ujRd0E/hqdefault.jpg"
  },
  {
    videoID: "ks_tCSuZbCQ",
    company: "ALIEN WORKSHOP",
    title: "MEMORY SCREEN",
    thumbnail: "https://i.ytimg.com/vi/ks_tCSuZbCQ/hqdefault.jpg"
  },
  {
    videoID: "SDpzSja058g",
    company: "ALIEN WORKSHOP",
    title: "PHOTOSYNTHESIS",
    thumbnail: "https://i.ytimg.com/vi/SDpzSja058g/hqdefault.jpg"
  },
  {
    videoID: "M_AD4qV6CMg",
    company: "ALIEN WORKSHOP",
    title: "MIND FIELD",
    thumbnail: "https://i.ytimg.com/vi/M_AD4qV6CMg/hqdefault.jpg"
  },
  {
    videoID: "RJMvLinTnO0",
    company: "BLACK OUT",
    thumbnail: "https://i.ytimg.com/vi/RJMvLinTnO0/hqdefault.jpg"
  },
  {
    videoID: "DEojYox_azk",
    company: "BAKER",
    title: "BAKER BOOTLEG",
    thumbnail: "https://i.ytimg.com/vi/DEojYox_azk/hqdefault.jpg"
  },
  {
    videoID: "DfngxH0ZxMI",
    company: "BAKER",
    title: "BAKER HAS A DEATHWISH",
    thumbnail: "https://i.ytimg.com/vi/DfngxH0ZxMI/hqdefault.jpg"
  },
  {
    videoID: "aLwk3-LPIxQ",
    company: "BAKER",
    title: "BAKER 3",
    thumbnail: "https://i.ytimg.com/vi/aLwk3-LPIxQ/hqdefault.jpg"
  },
  {
    videoID: "CwYdP1r_sKM",
    company: "DECA",
    title: "ROUND 1",
    thumbnail: "https://i.ytimg.com/vi/CwYdP1r_sKM/hqdefault.jpg"
  },
  {
    videoID: "eQX9Vcduo3w",
    company: "CITY STARS",
    title: "STREET CINEMA",
    thumbnail: "https://i.ytimg.com/vi/eQX9Vcduo3w/hqdefault.jpg"
  },
  {
    videoID: "CwYdP1r_sKM",
    company: "DECA",
    title: "ROUND 1",
    thumbnail: "https://i.ytimg.com/vi/CwYdP1r_sKM/hqdefault.jpg"
  },
  {
    videoID: "6v7G274B42k",
    company: "DECA",
    title: "ROUND 2",
    thumbnail: "https://i.ytimg.com/vi/6v7G274B42k/hqdefault.jpg"
  },
  {
    videoID: "NNrQWpfAz1g",
    company: "DGK",
    title: "BEWARE OF THE UNDERDOGS",
    thumbnail: "https://i.ytimg.com/vi/NNrQWpfAz1g/hqdefault.jpg"
  },
  {
    videoID: "Jp9ioAABrrw",
    company: "DGK",
    title: "BLOOD MONEY",
    thumbnail: "https://i.ytimg.com/vi/Jp9ioAABrrw/hqdefault.jpg"
  },
  {
    videoID: "F-_gEEiKfSs",
    company: "EXPEDITION-ONE",
    title: "GONE FISHIN",
    thumbnail: "https://i.ytimg.com/vi/F-_gEEiKfSs/hqdefault.jpg"
  },
  {
    videoID: "jwkqPUTfgjc",
    company: "EXPEDITION-ONE",
    title: "MADNESS",
    thumbnail: "https://i.ytimg.com/vi/jwkqPUTfgjc/hqdefault.jpg"
  },
  {
    videoID: "xjmyx1eH2w4",
    company: "GIRL",
    title: "DOLL",
    thumbnail: "https://i.ytimg.com/vi/xjmyx1eH2w4/hqdefault.jpg"
  },
  {
    videoID: "0h38x668DMU",
    company: "GIRL",
    title: "HARSH EURO BARGE",
    thumbnail: "https://i.ytimg.com/vi/0h38x668DMU/hqdefault.jpg"
  },
  {
    videoID: "Qp_vOLB-OUE",
    company: "GIRL",
    title: "MOUSE",
    thumbnail: "https://i.ytimg.com/vi/Qp_vOLB-OUE/hqdefault.jpg"
  },
  {
    videoID: "NwF2R51ao2I",
    company: "GIRL",
    title: "CHOCOLATE TOUR",
    thumbnail: "https://i.ytimg.com/vi/NwF2R51ao2I/hqdefault.jpg"
  },
  {
    videoID: "Xx0SUTyhfFc",
    company: "GIRL",
    title: "HOT CHOCOLATE TOUR",
    thumbnail: "https://i.ytimg.com/vi/Xx0SUTyhfFc/hqdefault.jpg"
  },
  {
    videoID: "FCiR2b8UCj8",
    company: "GIRL",
    title: "YEAH RIGHT",
    thumbnail: "https://i.ytimg.com/vi/FCiR2b8UCj8/hqdefault.jpg"
  },
  {
    videoID: "TNqdsRrJJSw",
    company: "HABITAT",
    title: "MOSIAC",
    thumbnail: "https://i.ytimg.com/vi/TNqdsRrJJSw/hqdefault.jpg"
  },
  {
    videoID: "XZYBWmvCzcc",
    company: "HABITAT",
    title: "INHABITANTS",
    thumbnail: "https://i.ytimg.com/vi/XZYBWmvCzcc/hqdefault.jpg"
  },
  {
    videoID: "4G7SSnHLkYY",
    company: "HABITAT",
    title: "ORIGIN",
    thumbnail: "https://i.ytimg.com/vi/4G7SSnHLkYY/hqdefault.jpg"
  },
  {
    videoID: "yGd6unBhJ5I",
    company: "HABITAT",
    title: "SEARCH THE HORIZON",
    thumbnail: "https://i.ytimg.com/vi/yGd6unBhJ5I/hqdefault.jpg"
  },
  {
    videoID: "EQKHtCI97_I",
    title: "INFMS",
    thumbnail: "https://i.ytimg.com/vi/EQKHtCI97_I/hqdefault.jpg"
  },
  {
    videoID: "w_JIU6qczK4",
    company: "KAYO",
    title: "ITS OFFICIAL",
    thumbnail: "https://i.ytimg.com/vi/w_JIU6qczK4/hqdefault.jpg"
  },
  {
    videoID: "n_-Hd-YCUJQ",
    company: "LORDZ",
    title: "THEY DONT GIVE A FUCK ABOUT US",
    thumbnail: "https://i.ytimg.com/vi/n_-Hd-YCUJQ/hqdefault.jpg"
  },
  {
    videoID: "CBbHAnIu9PE",
    company: "SHORTYS",
    title: "FULFILL THE DREAM",
    thumbnail: "https://i.ytimg.com/vi/CBbHAnIu9PE/hqdefault.jpg"
  },
  {
    videoID: "gXJ98PDg-U4",
    company: "SHORTYS",
    title: "CHURCH OF SKATAN",
    thumbnail: "https://i.ytimg.com/vi/gXJ98PDg-U4/hqdefault.jpg"
  },
  {
    videoID: "5nsMawTozJk",
    title: "EST",
    thumbnail: "https://i.ytimg.com/vi/5nsMawTozJk/hqdefault.jpg"
  },
  {
    videoID: "odwUc9CcTBU",
    title: "EST v.2",
    thumbnail: "https://i.ytimg.com/vi/odwUc9CcTBU/hqdefault.jpg"
  },
  {
    videoID: "7UJo8Vhk-Pg",
    title: "EST v.3",
    thumbnail: "https://i.ytimg.com/vi/7UJo8Vhk-Pg/hqdefault.jpg"
  },
  {
    videoID: "fQZT8zvR_Y4",
    title: "EST v.4",
    thumbnail: "https://i.ytimg.com/vi/fQZT8zvR_Y4/hqdefault.jpg"
  },
  {
    videoID: "Xhuq9sSsvV4",
    company: "ZOO YORK",
    title: "MIXTAPE",
    thumbnail: "https://i.ytimg.com/vi/Xhuq9sSsvV4/hqdefault.jpg"
  },
  {
    videoID: "LaqoIWv2Cig",
    company: "ZOO YORK",
    title: "UNBREAKABLE",
    thumbnail: "https://i.ytimg.com/vi/LaqoIWv2Cig/hqdefault.jpg"
  },
  {
    videoID: "KTZI92s6l3M",
    company: "ZOO YORK",
    title: "PEEP THIS",
    thumbnail: "https://i.ytimg.com/vi/KTZI92s6l3M/hqdefault.jpg"
  },
  {
    videoID: "Ik5tmIYB3ec",
    company: "ZOO YORK",
    title: "CITY OF KILLERS",
    thumbnail: "https://i.ytimg.com/vi/Ik5tmIYB3ec/hqdefault.jpg"
  },
  {
    videoID: "vSvsBZMJ0RE",
    company: "EASTERN EXPOSURE",
    title: "ZERO",
    thumbnail: "https://i.ytimg.com/vi/vSvsBZMJ0RE/hqdefault.jpg"
  },
  {
    videoID: "aXHacgR1ZEQ",
    company: "EASTERN EXPOSURE",
    title: "TWO",
    thumbnail: "https://i.ytimg.com/vi/aXHacgR1ZEQ/hqdefault.jpg"
  },
  {
    videoID: "GfSZXQ3YeRk",
    company: "EASTERN EXPOSURE",
    title: "UNDERACHIEVERS",
    thumbnail: "https://i.ytimg.com/vi/GfSZXQ3YeRk/hqdefault.jpg"
  }
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={43}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>FRESH</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {fresh.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "10px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h6> {obj.company}</h6>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
