import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const grosso = [
  {
    videoID: "uq9cTgystuc",
    title: "Venice Beach",
    thumbnail: "https://i.ytimg.com/vi/uq9cTgystuc/hqdefault.jpg",
  },
  {
    videoID: "1nZZ6p3y7GM",
    title: "5 Great DIY",
    thumbnail: "https://i.ytimg.com/vi/1nZZ6p3y7GM/hqdefault.jpg",
  },
  {
    videoID: "ifhAOpXH1PM",
    title: "China",
    thumbnail: "https://i.ytimg.com/vi/ifhAOpXH1PM/hqdefault.jpg",
  },
  {
    videoID: "rFF4ejYnAec",
    title: "Lizzie",
    thumbnail: "https://i.ytimg.com/vi/rFF4ejYnAec/hqdefault.jpg",
  },
  {
    videoID: "hkjmZa00WvQ",
    title: "Brazil",
    thumbnail: "https://i.ytimg.com/vi/hkjmZa00WvQ/hqdefault.jpg",
  },
  {
    videoID: "s2uK4zN-K_k",
    title: "Canada",
    thumbnail: "https://i.ytimg.com/vi/s2uK4zN-K_k/hqdefault.jpg",
  },
  {
    videoID: "XZNH7jYVY0o",
    title: "France",
    thumbnail: "https://i.ytimg.com/vi/XZNH7jYVY0o/hqdefault.jpg",
  },
  {
    videoID: "w_8bzOqODXg",
    title: "Geoff Rowley",
    thumbnail: "https://i.ytimg.com/vi/w_8bzOqODXg/hqdefault.jpg",
  },
  {
    videoID: "Xtgwsq-fjDw",
    title: "AVE",
    thumbnail: "https://i.ytimg.com/vi/Xtgwsq-fjDw/hqdefault.jpg",
  },
  {
    videoID: "RUdo1A98C2w",
    title: "Agro Zone",
    thumbnail: "https://i.ytimg.com/vi/RUdo1A98C2w/hqdefault.jpg",
  },
  {
    videoID: "_MplK_UbRd8",
    title: "Hawaii",
    thumbnail: "https://i.ytimg.com/vi/_MplK_UbRd8/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={11}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>LOVE LETTERS</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {grosso.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "5px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h5> {obj.author}</h5>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
