import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const current = [
  {
    title: "SKATELINE",
    date: "APR.1",
    videoID: "Ql4ZOyD8tAM",
    thumbnail: "https://i.ytimg.com/vi/Ql4ZOyD8tAM/hqdefault.jpg"
  },
  {
    title: "SKATELINE",
    date: "MAR.24",
    videoID: "zopWsw9jdio",
    thumbnail: "https://i.ytimg.com/vi/f7GTQ526kCU/hqdefault.jpg"
  },
  {
    title: "QSTOP10",
    date: "MAR.20",
    videoID: "Tx5M2xbLbgQ",
    thumbnail: "https://i.ytimg.com/vi/Tx5M2xbLbgQ/hqdefault.jpg"
  },
  {
    title: "SKATELINE",
    date: "MAR.17",
    videoID: "WBbXHWHgNy8",
    thumbnail: "https://i.ytimg.com/vi/xN1KHIH7wS8/hqdefault.jpg"
  },
  {
    title: "SKATELINE",
    date: "MAR.10",
    videoID: "aXErwpyozuM",
    thumbnail: "https://i.ytimg.com/vi/QUIrkX9ljds/hqdefault.jpg"
  },
  {
    title: "QSTOP10",
    date: "MAR.6",
    videoID: "Ieo_8GGwJcc",
    thumbnail: "https://i.ytimg.com/vi/bj8m5hItVRI/hqdefault.jpg"
  },
  {
    title: "JENKEM",
    date: "MONSTER",
    videoID: "AC3AN9qNzJY",
    thumbnail: "https://i.ytimg.com/vi/AC3AN9qNzJY/hqdefault.jpg"
  }
];
export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={7}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>FOR THE CULTURE</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <div>
            <Slider className="cards">
              <a href="#playa">
                {current.map((obj, i) => (
                  <Slide index={i} key={i}>
                    <div style={{ padding: "10px" }}>
                      <img
                        width="100%"
                        src={obj.thumbnail}
                        onClick={() => this.props.onChangeUrl(obj.videoID)}
                        alt=""
                      />
                      <h5>{obj.title}</h5>
                      <h6 className={"carddate"}>{obj.date}</h6>
                    </div>
                  </Slide>
                ))}
              </a>
            </Slider>
          </div>
        </div>
      </CarouselProvider>
    );
  }
}
