import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const jenkem = [
  {
    videoID: "bSJwIKf_jO0",
    title: "D7 blocks",
    thumbnail: "https://i.ytimg.com/vi/bSJwIKf_jO0/hqdefault.jpg",
  },
  {
    videoID: "zsNlOcIqPLU",
    title: "Black Hubba",
    thumbnail: "https://i.ytimg.com/vi/zsNlOcIqPLU/hqdefault.jpg",
  },
  {
    videoID: "n6pc1fs3ik8",
    title: "Courthouse Drop",
    thumbnail: "https://i.ytimg.com/vi/n6pc1fs3ik8/hqdefault.jpg",
  },
  {
    videoID: "r3x6Bsuu7o0",
    title: "Jerome ave banks",
    thumbnail: "https://i.ytimg.com/vi/r3x6Bsuu7o0/hqdefault.jpg",
  },
  {
    videoID: "iqjL98pA9Go",
    title: "MLK ledges",
    thumbnail: "https://i.ytimg.com/vi/iqjL98pA9Go/hqdefault.jpg",
  },
  {
    videoID: "jCDXfEtCapE",
    title: "NYC pyramid ledge",
    thumbnail: "https://i.ytimg.com/vi/jCDXfEtCapE/hqdefault.jpg",
  },
  {
    videoID: "2KuRQi0rLb0",
    title: "LA courthouse",
    thumbnail: "https://i.ytimg.com/vi/2KuRQi0rLb0/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={10}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>JENKEM EVOLUTION</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {jenkem.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "5px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h5> {obj.author}</h5>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
