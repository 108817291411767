import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const shop = [
  {
    videoID: "pIN0h5h2kMU",
    company: "ANTI-SOCIAL",
    title: "North",
    thumbnail: "https://i.ytimg.com/vi/pIN0h5h2kMU/hqdefault.jpg",
  },
  {
    videoID: "FEmOMUa84oI",
    company: "ANDREW",
    title: "FLOATER",
    thumbnail: "https://i.ytimg.com/vi/FEmOMUa84oI/hqdefault.jpg",
  },
  {
    videoID: "n5WPeXhAFtM",
    company: "BLUE TILE LOUNGE",
    title: "BABY BLUE",
    thumbnail: "https://i.ytimg.com/vi/0IFBrv1zSac/hqdefault.jpg",
  },
  {
    videoID: "oFV34M02KRE",
    company: "COLISEUM",
    title: "pj ladd's wonderful, horrible, life",
    thumbnail: "https://i.ytimg.com/vi/oFV34M02KRE/hqdefault.jpg",
  },
  {
    videoID: "JrCdpu6RHOs",
    company: "DIME",
    title: "the dime video",
    thumbnail: "https://i.ytimg.com/vi/JrCdpu6RHOs/hqdefault.jpg",
  },
  {
    videoID: "tkdiko790fw",
    company: "DIME",
    title: "knowing mixtape 2",
    thumbnail: "https://i.ytimg.com/vi/tkdiko790fw/hqdefault.jpg",
  },
  {
    videoID: "a5WppqY_Fhk",
    company: "GREEN APPLE",
    title: "modern love",
    thumbnail: "https://i.ytimg.com/vi/a5WppqY_Fhk/hqdefault.jpg",
  },
  {
    videoID: "dpFHRsSR0gg",
    company: "GREEN APPLE",
    title: "peep show",
    thumbnail: "https://i.ytimg.com/vi/dpFHRsSR0gg/hqdefault.jpg",
  },
  {
    videoID: "pNUtvD8Zvv4",
    company: "LOTTIES",
    title: "primetime",
    thumbnail: "https://i.ytimg.com/vi/pNUtvD8Zvv4/hqdefault.jpg",
  },
  {
    videoID: "xRXtzU0WiEk",
    company: "LOTTIES",
    title: "ON GOD",
    thumbnail: "https://i.ytimg.com/vi/xRXtzU0WiEk/hqdefault.jpg",
  },
  {
    videoID: "gpcrCFydm8E",
    company: "MIA",
    title: "welcome to MIA",
    thumbnail: "https://i.ytimg.com/vi/gpcrCFydm8E/hqdefault.jpg",
  },
  {
    videoID: "0IFBrv1zSac",
    company: "SUPREME",
    title: "A LOVE SUPREME",
    thumbnail: "https://i.ytimg.com/vi/0IFBrv1zSac/hqdefault.jpg",
  },
  {
    videoID: "Yk1cLKpzHck",
    company: "SUPREME",
    title: "candyland",
    thumbnail: "https://i.ytimg.com/vi/Yk1cLKpzHck/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={13}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>SHOPTALK</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {shop.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "10px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h6> {obj.company}</h6>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
