import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const hesh = [
  {
    videoID: "I7ALfsE-5nA",
    company: "ANTIHERO",
    title: "FUCKTARDS",
    thumbnail: "https://i.ytimg.com/vi/I7ALfsE-5nA/hqdefault.jpg"
  },
  {
    videoID: "aATR2vf222k",
    company: "ANTIHERO",
    title: "COW",
    thumbnail: "https://i.ytimg.com/vi/aATR2vf222k/hqdefault.jpg"
  },
  {
    videoID: "kJUaWqNWfVY",
    company: "ANTIHERO",
    title: "TWO SONGS",
    thumbnail: "https://i.ytimg.com/vi/kJUaWqNWfVY/hqdefault.jpg"
  },
  {
    videoID: "2jyq_tlJZaI",
    company: "ANTIHERO",
    title: "CASH MONEY VAGRANT",
    thumbnail: "https://i.ytimg.com/vi/2jyq_tlJZaI/hqdefault.jpg"
  },
  {
    videoID: "QrRy3Ui8dCg",
    company: "FOUNDATION",
    title: "THATS LIFE",
    thumbnail: "https://i.ytimg.com/vi/QrRy3Ui8dCg/hqdefault.jpg"
  },
  {
    videoID: "Fp-LIZiCgfI",
    company: "FOUNDATION",
    title: "ART BARS",
    thumbnail: "https://i.ytimg.com/vi/Fp-LIZiCgfI/hqdefault.jpg"
  },
  {
    videoID: "b6QXeorfmv4",
    company: "TOY MACHINE",
    title: "HEAVY METAL",
    thumbnail: "https://i.ytimg.com/vi/b6QXeorfmv4/hqdefault.jpg"
  },
  {
    videoID: "rF1eRPtbCk4",
    company: "TOY MACHINE",
    title: "WELCOME TO HELL",
    thumbnail: "https://i.ytimg.com/vi/rF1eRPtbCk4/hqdefault.jpg"
  },
  {
    videoID: "dO8jD4_cu7M",
    company: "TOY MACHINE",
    title: "JUMP OFF A BUILDING",
    thumbnail: "https://i.ytimg.com/vi/dO8jD4_cu7M/hqdefault.jpg"
  },
  {
    videoID: "2RNRzWPILXk",
    company: "ZERO",
    title: "MISLED YOUTH",
    thumbnail: "https://i.ytimg.com/vi/2RNRzWPILXk/hqdefault.jpg"
  },
  {
    videoID: "CLaxmyL1sB0",
    company: "ZERO",
    title: "THRILL OF IT ALL",
    thumbnail: "https://i.ytimg.com/vi/CLaxmyL1sB0/hqdefault.jpg"
  },
  {
    videoID: "jlr2MIP2NfI",
    company: "ZERO",
    title: "DYING TO LIVE",
    thumbnail: "https://i.ytimg.com/vi/jlr2MIP2NfI/hqdefault.jpg"
  },
  {
    videoID: "yhKCQXwoCEI",
    company: "ZERO",
    title: "NEW BLOOD",
    thumbnail: "https://i.ytimg.com/vi/yhKCQXwoCEI/hqdefault.jpg"
  },
  {
    videoID: "tSm2x6SK3W0",
    company: "ZERO",
    title: "STRANGE WORLD",
    thumbnail: "https://i.ytimg.com/vi/tSm2x6SK3W0/hqdefault.jpg"
  },
  {
    videoID: "ILN9Tc3GT7c",
    company: "ZERO",
    title: "COLD WORLD",
    thumbnail: "https://i.ytimg.com/vi/ILN9Tc3GT7c/hqdefault.jpg"
  }
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={15}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>HESH</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {hesh.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "10px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h6> {obj.company}</h6>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
