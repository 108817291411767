import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const skatehoarders = [
  {
    videoID: "690Oc1a9GiE",
    title: "Josh Kalis",
    thumbnail: "https://i.ytimg.com/vi/690Oc1a9GiE/hqdefault.jpg",
  },
  {
    videoID: "Y9qMBFcCLXY",
    title: "Steve Caballero",
    thumbnail: "https://i.ytimg.com/vi/Y9qMBFcCLXY/hqdefault.jpg",
  },
  {
    videoID: "WXu0rZZ6G-o",
    title: "NHS",
    thumbnail: "https://i.ytimg.com/vi/WXu0rZZ6G-o/hqdefault.jpg",
  },
  {
    videoID: "3Dsdtykn_l0",
    title: "Jonathan Mehring",
    thumbnail: "https://i.ytimg.com/vi/3Dsdtykn_l0/hqdefault.jpg",
  },
  {
    videoID: "UpJqlBtDAoc",
    title: "Jaime Owens",
    thumbnail: "https://i.ytimg.com/vi/UpJqlBtDAoc/hqdefault.jpg",
  },
  {
    videoID: "dRszuUytKsg",
    title: "John Rattray",
    thumbnail: "https://i.ytimg.com/vi/dRszuUytKsg/hqdefault.jpg",
  },
  {
    videoID: "KBAheqL6GjQ",
    title: "Tony Hawk",
    thumbnail: "https://i.ytimg.com/vi/KBAheqL6GjQ/hqdefault.jpg",
  },
  {
    videoID: "cdsS9XKcQUw",
    title: "Wes Kremer",
    thumbnail: "https://i.ytimg.com/vi/cdsS9XKcQUw/hqdefault.jpg",
  },
  {
    videoID: "4p2yr3AFISk",
    title: "Mike Sinclair",
    thumbnail: "https://i.ytimg.com/vi/4p2yr3AFISk/hqdefault.jpg",
  },
  {
    videoID: "CTHbfiLGSIo",
    title: "Jason Rothmeyer",
    thumbnail: "https://i.ytimg.com/vi/CTHbfiLGSIo/hqdefault.jpg",
  },
  {
    videoID: "HMMnsdUv3D8",
    title: "Roger Bagley",
    thumbnail: "https://i.ytimg.com/vi/HMMnsdUv3D8/hqdefault.jpg",
  },
  {
    videoID: "10QCsgMC668",
    title: "Mackenzie Eisenhour",
    thumbnail: "https://i.ytimg.com/vi/10QCsgMC668/hqdefault.jpg",
  },
  {
    videoID: "mX_YIkxBPqY",
    title: "Six Stair Films",
    thumbnail: "https://i.ytimg.com/vi/mX_YIkxBPqY/hqdefault.jpg",
  },
  {
    videoID: "wSiZLbGWnpM",
    title: "Fred Gall",
    thumbnail: "https://i.ytimg.com/vi/wSiZLbGWnpM/hqdefault.jpg",
  },
  {
    videoID: "gqwZL7KLbrE",
    title: "Beagle",
    thumbnail: "https://i.ytimg.com/vi/gqwZL7KLbrE/hqdefault.jpg",
  },
  {
    videoID: "_jA7LfEH8cE",
    title: "Mark Appleyard",
    thumbnail: "https://i.ytimg.com/vi/_jA7LfEH8cE/hqdefault.jpg",
  },
  {
    videoID: "r8lctLF85YI",
    title: "Joey Brezinski",
    thumbnail: "https://i.ytimg.com/vi/r8lctLF85YI/hqdefault.jpg",
  },
  {
    videoID: "dPGAgu92bjs",
    title: "Bobshirt",
    thumbnail: "https://i.ytimg.com/vi/dPGAgu92bjs/hqdefault.jpg",
  },
  {
    videoID: "9R-lXs8XiKI",
    title: "Thomas Campbell",
    thumbnail: "https://i.ytimg.com/vi/9R-lXs8XiKI/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={19}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>SKATEHOARDERS</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {skatehoarders.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "5px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h5> {obj.author}</h5>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
