import React from "react";
import logo from "./icons/cridflixx.jpg";
import blunt from "./icons/bluntt.jpg";
import kids from "./icons/kids.jpg";
import bell from "./icons/bell.png";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="headerleft">
          <img src={logo} alt="" id="logo" />
        </div>
        <div className="headerright">
          <img src={kids} alt="" id="kids" />
          <img src={bell} alt="" id="bell" />
          <img src={blunt} alt="" id="blunt" />
        </div>
      </div>
    );
  }
}
