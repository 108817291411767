import React from "react";
import ReactPlayer from "react-player";

export default class Hover extends React.Component {
  state = { playing: false };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  handleStop = () => {
    this.setState({ playing: false });
  };
  render() {
    return (
      <div onMouseOver={this.handlePlayPause} onMouseLeave={this.handleStop}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=SFmxVFg42kI"
          width="100%"
          height="80vh"
          {...this.state}
        />
      </div>
    );
  }
}
