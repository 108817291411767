import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const hotshit = [
  {
    company: "917",
    videoID: "kzg0Ua7q-oo",
    title: "CALL ME 917",
    thumbnail: "https://i.ytimg.com/vi/kzg0Ua7q-oo/hqdefault.jpg",
  },
  {
    company: "ALLTIMERS",
    videoID: "2oKyWnrxIB4",
    title: "NO IDEA",
    thumbnail: "https://i.ytimg.com/vi/2oKyWnrxIB4/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "23o8USGrVDY",
    title: "***2020***",
    thumbnail: "https://i.ytimg.com/vi/23o8USGrVDY/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "gjhtVF5a-34",
    title: "***IT'S TIME***",
    thumbnail: "https://i.ytimg.com/vi/gjhtVF5a-34/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "wmQJ6_g9p4A",
    title: "***ENRON***",
    thumbnail: "https://i.ytimg.com/vi/wmQJ6_g9p4A/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "1SqQpymDqJ0",
    title: "***A1***",
    thumbnail: "https://i.ytimg.com/vi/1SqQpymDqJ0/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "f5WiJVz7pFY",
    title: "***SOLO JAZZ***",
    thumbnail: "https://i.ytimg.com/vi/f5WiJVz7pFY/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "9dKOl9xX0lA",
    title: "***TRUST***",
    thumbnail: "https://i.ytimg.com/vi/9dKOl9xX0lA/hqdefault.jpg",
  },
  {
    company: "BRONZE",
    videoID: "mEDi5qSWf8s",
    title: "***56K***",
    thumbnail: "https://i.ytimg.com/vi/mEDi5qSWf8s/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "ebX27CcHyVc",
    title: "dancing on thin ice",
    thumbnail: "https://i.ytimg.com/vi/ebX27CcHyVc/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "_l1vDhCAPh8",
    title: "FIGHT FUCK",
    thumbnail: "https://i.ytimg.com/vi/_l1vDhCAPh8/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "LG-9X8GMNoI",
    title: "FIGHT FUCK 2",
    thumbnail: "https://i.ytimg.com/vi/LG-9X8GMNoI/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "ZkCvk7z8Gsc",
    title: "HOCKEY PROMO",
    thumbnail: "https://i.ytimg.com/vi/ZkCvk7z8Gsc/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "l2R6t1gz_Sw",
    title: "HOCKEY II",
    thumbnail: "https://i.ytimg.com/vi/l2R6t1gz_Sw/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "CYjh7P4cXXY",
    title: "HOCKEY III",
    thumbnail: "https://i.ytimg.com/vi/CYjh7P4cXXY/hqdefault.jpg",
  },
  {
    company: "FA",
    videoID: "do6LJXfr43Y",
    title: "fuck a rough cut",
    thumbnail: "https://i.ytimg.com/vi/do6LJXfr43Y/hqdefault.jpg",
  },
  {
    company: "GX",
    videoID: "FxDVObzf5y0",
    title: "roll up",
    thumbnail: "https://i.ytimg.com/vi/FxDVObzf5y0/hqdefault.jpg",
  },
  {
    company: "GX",
    videoID: "cgiFHll1tBk",
    title: "adrenaline junkie",
    thumbnail: "https://i.ytimg.com/vi/cgiFHll1tBk/hqdefault.jpg",
  },
  {
    company: "GX",
    videoID: "pnzgRB8qH4k",
    title: "el camino",
    thumbnail: "https://i.ytimg.com/vi/pnzgRB8qH4k/hqdefault.jpg",
  },
  {
    company: "GX",
    videoID: "LK03quRPXMk",
    title: "sf is where the heart is",
    thumbnail: "https://i.ytimg.com/vi/LK03quRPXMk/hqdefault.jpg",
  },
  {
    company: "HARDBODY",
    videoID: "-qhwVDUsH9Q",
    title: "THE DANY VIDEO",
    thumbnail: "https://i.ytimg.com/vi/-qhwVDUsH9Q/hqdefault.jpg",
  },
  {
    company: "HOTEL BLUE",
    videoID: "O9haf30AJ3Q",
    title: "HOTEL BLUE",
    thumbnail: "https://i.ytimg.com/vi/O9haf30AJ3Q/hqdefault.jpg",
  },
  {
    company: "HOTEL BLUE",
    videoID: "mPjlyp22lLQ",
    title: "ROUGH LUXURY",
    thumbnail: "https://i.ytimg.com/vi/mPjlyp22lLQ/hqdefault.jpg",
  },
  {
    company: "HOTEL BLUE",
    videoID: "R7tIlKGlcKw",
    title: '"EXHAUST"',
    thumbnail: "https://i.ytimg.com/vi/R7tIlKGlcKw/hqdefault.jpg",
  },
  {
    company: "HOTEL BLUE",
    videoID: "W9BKIKO6DOE",
    title: "ALL FLAT",
    thumbnail: "https://i.ytimg.com/vi/W9BKIKO6DOE/hqdefault.jpg",
  },
  {
    company: "NOAH",
    videoID: "r7-AmpEGZ54",
    title: "JOLIE ROUGE",
    thumbnail: "https://i.ytimg.com/vi/r7-AmpEGZ54/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "IsRtVxTLKww",
    title: "BETAMAXIMUM",
    thumbnail: "https://i.ytimg.com/vi/IsRtVxTLKww/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "9QvMxdM1K3o",
    title: "NORMCORE",
    thumbnail: "https://i.ytimg.com/vi/9QvMxdM1K3o/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "KlxSQa4m49Y",
    title: "THE MERCHANDISE",
    thumbnail: "https://i.ytimg.com/vi/KlxSQa4m49Y/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "NREZyxmPqPE",
    title: "V NICE",
    thumbnail: "https://i.ytimg.com/vi/NREZyxmPqPE/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "IWDLSAbu4Nk",
    title: "ENDLESS BUMMER",
    thumbnail: "https://i.ytimg.com/vi/IWDLSAbu4Nk/hqdefault.jpg",
  },
  {
    company: "PALACE",
    videoID: "dCSL6WPvUGk",
    title: "PARAMOUNT",
    thumbnail: "https://i.ytimg.com/vi/dCSL6WPvUGk/hqdefault.jpg",
  },
  {
    company: "QUASI",
    videoID: "jPr8RxWm5ZA",
    title: "MOTHER",
    thumbnail: "https://i.ytimg.com/vi/jPr8RxWm5ZA/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={34}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>HOTSHIT</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {hotshit.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "5px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h5> {obj.company}</h5>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
