import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import left from "../icons/left.png";
import right from "../icons/right.png";

const pocket = [
  {
    videoID: "wVTsZYndFVI",
    title: "Carlos Ribeiro",
    thumbnail: "https://i.ytimg.com/vi/wVTsZYndFVI/hqdefault.jpg",
  },
  {
    videoID: "WGknixHHQrI",
    title: "Franky Villani",
    thumbnail: "https://i.ytimg.com/vi/WGknixHHQrI/hqdefault.jpg",
  },
  {
    videoID: "JV92xs228PY",
    title: "Jake Anderson",
    thumbnail: "https://i.ytimg.com/vi/JV92xs228PY/hqdefault.jpg",
  },
  {
    videoID: "wp8A7tiR5_w",
    title: "Chico Brenes",
    thumbnail: "https://i.ytimg.com/vi/wp8A7tiR5_w/hqdefault.jpg",
  },
  {
    videoID: "z7uVe1C0Gwo",
    title: "Jordan Taylor",
    thumbnail: "https://i.ytimg.com/vi/z7uVe1C0Gwo/hqdefault.jpg",
  },
  {
    videoID: "QerzY1l3mu0",
    title: "Griffin Gass",
    thumbnail: "https://i.ytimg.com/vi/QerzY1l3mu0/hqdefault.jpg",
  },
  {
    videoID: "5X14OI48qiQ",
    title: "Vincent Alvarez",
    thumbnail: "https://i.ytimg.com/vi/5X14OI48qiQ/hqdefault.jpg",
  },
  {
    videoID: "QUT0DcFzWxI",
    title: "Beatrice Domond",
    thumbnail: "https://i.ytimg.com/vi/QUT0DcFzWxI/hqdefault.jpg",
  },
  {
    videoID: "dIS28C6uCks",
    title: "Chad Tim Tim",
    thumbnail: "https://i.ytimg.com/vi/dIS28C6uCks/hqdefault.jpg",
  },
  {
    videoID: "33RmUOwdwn4",
    title: "Nick Jensen",
    thumbnail: "https://i.ytimg.com/vi/33RmUOwdwn4/hqdefault.jpg",
  },
  {
    videoID: "5i_40ZjwR1s",
    title: "Giovanni Vianna",
    thumbnail: "https://i.ytimg.com/vi/5i_40ZjwR1s/hqdefault.jpg",
  },
  {
    videoID: "ynn1r-PWJdA",
    title: "Trent McClung",
    thumbnail: "https://i.ytimg.com/vi/ynn1r-PWJdA/hqdefault.jpg",
  },
  {
    videoID: "M-BDGjPHzfg",
    title: "Yonnie Cruz",
    thumbnail: "https://i.ytimg.com/vi/M-BDGjPHzfg/hqdefault.jpg",
  },
];

export default class extends React.Component {
  renderSlides() {
    if (599 > window.innerWidth) {
      return 4;
    } else if (window.innerWidth >= 600 && window.innerWidth <= 749) {
      return 5;
    } else if (window.innerWidth >= 750 && window.innerWidth <= 999) {
      return 6;
    } else if (window.innerWidth > 1000) {
      return 7;
    }
  }
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={5}
        naturalSlideHeight={7}
        totalSlides={13}
        touchEnabled={true}
        dragEnabled={true}
        visibleSlides={this.renderSlides()}
        step={4}
      >
        <div className="cards">
          <h2>POCKET</h2>
          <div className="sliderbuttonleft">
            <ButtonBack className={"buttonleft"}>
              <img
                src={left}
                alt="left"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonBack>
          </div>
          <div className="sliderbuttonright">
            <ButtonNext className={"buttonright"}>
              <img
                src={right}
                alt="right"
                style={{ height: "10px", width: "10px" }}
              />
            </ButtonNext>
          </div>
          <Slider>
            <a href="#playa">
              {pocket.map((obj, i) => (
                <Slide index={i} key={i}>
                  <div style={{ padding: "5px" }}>
                    <img
                      width="100%"
                      src={obj.thumbnail}
                      onClick={() => this.props.onChangeUrl(obj.videoID)}
                      alt=""
                    />
                    <h5> {obj.author}</h5>
                    <h6>{obj.title}</h6>
                  </div>
                </Slide>
              ))}
            </a>
          </Slider>
        </div>
      </CarouselProvider>
    );
  }
}
