import React, { Component, Suspense, lazy } from "react";
import YouTubePlayer from "react-player/lib/players/YouTube";
import PalaceBronze from "./components/Videos/HotShit";
import Shop from "./components/Videos/Shoptalk";
import Fresh from "./components/Videos/Fresh";
import Hesh from "./components/Videos/Hesh";
import News from "./components/Videos/News";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hover from "./components/Videos/Initial";
import Grosso from "./components/Videos/Grosso";
import Jenkem from "./components/Videos/Jenkem";
import Pocket from "./components/Videos/Pocket";
import Hoarders from "./components/Videos/Skatehoarders";
import ReactGA from "react-ga";

import "./App.css";

const Critical = lazy(() => import("./components/Videos/Critical"));
const LurkMean = lazy(() => import("./components/Videos/Lurk"));
const AD = lazy(() => import("./components/Videos/AtlanticDrift"));
const Mid90s = lazy(() => import("./components/Videos/Mid90s"));
const Footwear = lazy(() => import("./components/Videos/Footwear"));
const FourOneOne = lazy(() => import("./components/Videos/Fouroneone"));
const Transworld = lazy(() => import("./components/Videos/Transworld"));

class App extends Component {
  state = { url: null };
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.onChangeUrl = this.onChangeUrl.bind(this);
    window.addEventListener("resize", this.update);
    console.log(this.state.width);
  }

  initializeReactGA() {
    ReactGA.initialize("UA-133430964-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  onChangeUrl(newUrl) {
    this.setState({
      url: `https://www.youtube.com/watch?v=${newUrl}`,
    });
  }

  componentDidMount() {
    this.update();
    this.initializeReactGA();
  }

  update = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  render() {
    return (
      <div className="full">
        <Header />
        <div className="all">
          <div className="App" />
          <div className="topspace" />
          <Hover />
          <div>
            <News onChangeUrl={this.onChangeUrl} />
            <Fresh onChangeUrl={this.onChangeUrl} />
            <Hesh onChangeUrl={this.onChangeUrl} />
            <Pocket onChangeUrl={this.onChangeUrl} />
            <Shop onChangeUrl={this.onChangeUrl} />
            <Grosso onChangeUrl={this.onChangeUrl} />
            <PalaceBronze onChangeUrl={this.onChangeUrl} />
            <YouTubePlayer
              id="playa"
              url={this.state.url}
              playing
              controls
              loop
              width="100%"
              height="80vh"
            />
            <Suspense fallback={<h1>Hellllllo Loading…</h1>}>
              <Critical onChangeUrl={this.onChangeUrl} />
              <LurkMean onChangeUrl={this.onChangeUrl} />
              <Jenkem onChangeUrl={this.onChangeUrl} />
              <Hoarders onChangeUrl={this.onChangeUrl} />
              <AD onChangeUrl={this.onChangeUrl} />
              <Footwear onChangeUrl={this.onChangeUrl} />
              <Mid90s onChangeUrl={this.onChangeUrl} />
              <FourOneOne onChangeUrl={this.onChangeUrl} />
              <Transworld onChangeUrl={this.onChangeUrl} />
            </Suspense>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
