import React from "react";

export default class footer extends React.Component {
  render() {
    return (
      <div className="footer-wrap">
        <div className="footer-info-container">
          <div>
            <ul className="footer-info">
              <li>contact us</li>
            </ul>
          </div>
          <div>
            <ul className="footer-info">
              <li>legal notices</li>
            </ul>
          </div>

          <div>
            <ul className="footer-info">
              <li>terms of use</li>
            </ul>
          </div>
        </div>
        <div>
          <ul className="footer-info">
            <li>@themoreyousmoke</li>
          </ul>
        </div>
      </div>
    );
  }
}
